@charset "UTF-8";
@media (max-width: 1290px) {
  .form-control::placeholder,
    .form-control{
      font-size: 14px;
    }
    .input-group.multiFilter .form-control{
      padding-right: 30px;
    }
}

@media screen and (min-width: 991px) {
  .h-100-70{height: calc(100vh - 70px); overflow-y: auto;}
}


@media (max-width: 991px) {

  /*Account*/
  .account_page .left_side,
  .account_page .right_side {
    padding: 0px 0;
  }
  .page_body .left_side.active,
  .page_body .right_side{
    margin-left: 0;
  }
  .page_body .left_side{
    margin-left: -260px;
    z-index: 1000;
}
  #sidebar-overlay.active{
    display: block;
  }

}

@media (max-width: 767px) {

  /*Account*/
  .page_header .right_actions{
    width: 100%;
  }
  .page_body .right_side .content_wrapper{
    padding: 15px;
  }
}

@media (max-width: 575px) {

  /*Account*/
  .custom_tabs .nav .nav-item{
    flex: unset;
    width: 100%;
  }
  .w-100-120{width: 100%;}
  .w-100px{width: 100px;}
  .me-20px{margin-right: 0px;}    

}

@media (max-width: 480px) {}


