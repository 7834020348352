@charset "UTF-8";
/*Google Fonts*/
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');
@import './icon-moon.scss';

:root {
  /*Heading Fonts*/
  --heading-font: 'Poppins', sans-serif;
  /* Body font */
  --body-font: 'Poppins', sans-serif;
  /*Icon Font*/
  --icon-font: 'Font Awesome 5 Pro';
  --bs-icon-font: 'bootstrap-icons';
  /*Colors*/
  --white-color: #ffffff;
  --black-color: #000000;
  --color-one: #191515;
  --color-two: #261258;
  --color-link: #ba6da7;
  /*RGB Colors*/
  --white-color-rgb: 255, 255, 255;
  --black-color-rgb: 0, 0, 0;
  --color-one-rgb: 71, 57, 63;
  --color-two-rgb: 237, 30, 121;
}

/*General*/
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-track {
  background: hsla(0, 0%, 100%, 0.102);
}
::-webkit-scrollbar-thumb {
  background: hsla(0, 0%, 100%, 0.302);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: hsla(0, 0%, 100%, 0.302);
}
html {
  overflow-x: hidden;
}

body {
  font-family: var(--body-font);
  overflow-x: hidden;
  color: var(--white-color);
  font-size: 14px;
  line-height: 1.4;
  font-weight: 400;
  background-color: var(--white-color);
}

.relative {
  position: relative;
}

.section {
  position: relative;
  padding: 80px 0;
}

.section-padding {
  position: relative;
  padding: 80px 0 50px;
}

.image-fit {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.image-fit-contain {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.transform-center {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translate(0%, -50%);
  z-index: 1;
}

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

ul li {
  margin-bottom: 0;
  position: relative;
}

button:focus,
*:focus {
  outline: none;
}

button {
  background: transparent;
  border: none;
  padding: 0;
}

label {
  margin-bottom: 10px;
  font-weight: 500;
  line-height: normal;
}

img {
  max-width: 100%;
}
.link {
  color: var(--white-color);
  text-decoration: none;
  cursor: pointer;
  white-space: nowrap;
  /* word-break: break-all; */
}
.link:hover {
  color: var(--white-color);
  text-decoration: underline;
}
.section-bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  z-index: 1;
}

.section-bg-fix {
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  z-index: 1;
}

.section-bg:before,
.section-bg-fix:before {
  content: '';
  background-color: rgb(var(--black-color-rgb), 50%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.section-bg-fix.no-overlay:before,
.section-bg.no-overlay:before {
  display: none;
}

/* Typography */

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--heading-font);
  color: var(--white-color);
  font-weight: 500;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: var(--black-color);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 30px;
}

h1 {
  font-size: 60px;
}

h2 {
  font-size: 48px;
}

h3 {
  font-size: 30px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 22px;
}

h6 {
  font-size: 16px;
}

p {
  margin-bottom: 30px;
}

a {
  color: var(--black-color);
  transition: 0.3s all;
  display: inline-block;
  text-decoration: none;
}

a:hover,
a:focus {
  outline: none;
  box-shadow: none;
  text-decoration: none;
  color: var(--black-color);
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover {
  color: inherit;
}

.mb-xl-20 {
  margin-bottom: 20px;
}

.mb-xl-30 {
  margin-bottom: 30px;
}
/*Forms*/
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  margin: 0;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: textfield;
}
.form-group {
  margin-bottom: 25px;
}
.form-group + .invalid-feedback {
  margin-top: -15px;
}
.form-control {
  border-radius: 0px;
  border-color: var(--color-one);
  padding: 8px 15px;
  min-height: 43px;
}
.form-control.otpField {
  border-radius: 6px;
  max-width: 34px;
  margin: 0 8px 15px;
  padding-right: 0;
  padding-left: 0;
  text-align: center;
}
.form-control:focus {
  box-shadow: none;
  border-color: var(--color-one);
}
.form-control,
.form-control::placeholder {
  font-size: 16px;
  color: var(--color-one);
}
.type_password {
  position: relative;
}
.type_password .togglePassword {
  left: auto;
  right: 15px;
  z-index: 1;
  font-size: 16px;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
}
.type_password.with_label .togglePassword {
  top: 42px;
}
/* Custom input */
.custom-checkbox input[type='checkbox'] {
  font-size: 15px;
  appearance: none;
  width: 2.5em;
  height: 1.5em;
  background: #ddd;
  border-radius: 3em;
  position: relative;
  cursor: pointer;
  outline: none;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.custom-checkbox input[type='checkbox']:checked {
  background: var(--color-two);
}

.custom-checkbox input[type='checkbox']:after {
  position: absolute;
  content: '';
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.3);
  transform: scale(0.7);
  left: 0;
  transition: all 0.2s ease-in-out;
}

.custom-checkbox input[type='checkbox']:checked:after {
  left: calc(100% - 1.5em);
}

.custom-select,
.custom-search {
  position: relative;
}
.custom-select select {
  position: relative;
  z-index: 1;
  background: transparent;
  padding: 4px 35px 4px 15px;
}
.custom-select i {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 0;
}
.custom-search input[type='text'] {
  padding: 4px 45px 4px 15px;
  max-width: 250px;
}
.custom-search button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 2px 15px;
  height: 100%;
}
/*Buttons*/
.btn {
  border-radius: 25px;
  font-size: 16px;
  font-weight: 500;
  min-height: 43px;
  padding: 0.375rem 32px;
  box-shadow: 6px 8px 6px rgba(0, 0, 0, 0.58);
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.btn:not(.btn-icon) > i {
  margin-right: 8px;
}
.btn.btn-sm {
  padding: 0.25rem 0.5rem;
  min-height: auto;
}
.btn-primary {
  background: transparent linear-gradient(85deg, #0abab5, #3eb7f9) 0 0 no-repeat
    padding-box;
  border: none;
}
.btn-primary:hover {
  border-color: rgb(var(--color-two-rgb), 75%);
  background-color: rgb(var(--color-two-rgb), 75%);
  box-shadow: none;
}
.btn-primary.border {
  border-color: var(--color-two);
  background-color: transparent;
  color: var(--color-two);
}
.btn-primary.border:hover {
  border-color: rgb(var(--color-two-rgb), 75%);
  background-color: rgb(var(--color-two-rgb), 75%);
  color: var(--white-color);
}
/* Table */

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: #273b4b;
}
tr.cdk-drag {
  display: flex;
}
tr.cdk-drag td {
  flex: 1 0 0%;
}
tr.cdk-drag td,
.table thead tr th,
.table tbody tr td {
  vertical-align: middle;
  white-space: nowrap;
}
tr.cdk-drag td {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table.no_wrap thead tr th,
.table.no_wrap tbody tr td {
  white-space: normal;
  border-right: 1px solid #273b4b;
}
.table thead tr th {
  background-color: #0c1e2c;
  color: var(--white-color);
  border-bottom-color: #273b4b;
  font-weight: 500;
}
.table tbody tr td:last-child,
.table thead tr th:last-child {
  border-right: none;
}
.table tbody tr td .btn-icon {
  width: 30px;
  height: 30px;
  padding: 0;
  margin-right: 8px;
  background-color: #152a39;
  border-color: #152a39;
  color: var(--white-color);
  font-size: 16px;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  min-height: auto;
}
.table tbody tr td .btn-icon:hover {
  background-color: var(--color-one);
  border-color: var(--color-one);
  color: var(--white-color);
  box-shadow: none;
}
.table > tbody {
  border-top: none !important;
}
.table tr.cdk-drag {
  display: table-row;
}
.table tr.cdk-drag td {
  flex: auto;
}
/*Login page*/
.account_page .left_side,
.account_page .right_side {
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.account_page .right_side {
  background: url(../images/background.png) no-repeat center center/cover !important;
}
.account_page .right_side .form_info {
  width: 400px;
  background-color: #0c1e2c;
  box-shadow: -6px -6px 11px #1f3d55;
  border-radius: 2.6rem;
  padding: 2rem;
  text-align: left;
  position: relative;
  @media screen and (max-width: 450px) {
    width: 340px;
  }
}
.account_page .right_side .form_info::after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 2.6rem;
  box-shadow: 10px 10px 8px rgba(0, 0, 0, 0.5);
  pointer-events: none;
}
.account_page .right_side .form_info.hasImg {
  border-radius: 0.4rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  text-align: center;
}
.account_page .right_side .form_info.hasImg img {
  max-width: 80%;
  margin: 0 auto;
}
.account_page .left_side {
  background-color: var(--color-one);
}
.account_page .formbox .forgotPass {
  text-align: right;
  margin-top: 4px;
  font-size: 16px;
  font-weight: 500;
}
.account_page .formbox .btn_bottom {
  font-size: 16px;
  margin-top: 10px;
}
/* page_body */
.page_body {
  position: relative;
  height: 100vh;
  background-color: var(--white-color);
}
#sidebar-overlay {
  background-color: rgba(0, 0, 0, 0.8);
  bottom: 0;
  display: none;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 99;
}
.page_body .left_side {
  background: #152a39;
  border-right: 10px solid #273b4b;
  transition: margin-left 0.3s ease-in-out, width 0.3s ease-in-out;
  width: 260px;
  height: inherit;
  min-height: 100%;
  position: absolute;
  top: 0;
  z-index: 100;
  box-shadow: 0 14px 28px rgba(var(--black-color-rgb), 25%),
    0 10px 10px rgba(var(--black-color-rgb), 22%);
}
.page_body .right_side .page_topbar .inner::before,
.page_body .left_side::before {
  content: '';
  width: 100%;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  background: radial-gradient(
    circle,
    rgba(77, 192, 249, 0.2) 0,
    rgba(77, 192, 249, 0) 100%
  );
}
.page_body .left_side.active {
  margin-left: -260px;
}
.page_body .left_side .desk_logo {
  margin: 10px 0 0px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-bottom: 1px solid #273b4b;
  width: 100%;
  padding-bottom: 10px;
}
.page_body .left_side .desk_logo img {
  height: auto;
  margin: 0 auto;
}
.page_body .left_side .navigation .main_nav .menu-item > a {
  width: 100%;
  padding: 15px 20px;
  color: var(--white-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: 0.3s all;
  font-size: 14px;
  border-bottom: 1px solid #273b4b;
  position: relative;
}
.page_body .left_side .navigation .main_nav .menu-item.has-submenu > a {
  flex-direction: row-reverse;
}
.page_body .left_side .navigation .main_nav .menu-item.has-submenu > a::before {
  content: '\F282';
  font-family: var(--bs-icon-font);
  color: var(--white-color);
}

.page_body .left_side .navigation .main_nav .menu-item > a.active::after,
.page_body .left_side .navigation .main_nav .menu-item:hover > a::after {
  content: '';
  border-radius: 2rem;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: #00dbde;
  background-image: linear-gradient(315deg, #3eb7f9, #0abab5);
  filter: blur(0.8rem);
  transition: 0.5s ease;
}
.page_body .left_side .navigation .main_nav .menu-item:hover > a,
.page_body .left_side .navigation .main_nav .menu-item > a.active {
  outline: none;
  background-image: linear-gradient(#0c1e2c, #0c1e2c),
    linear-gradient(315deg, #3eb7f9, #0abab5);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  color: #fff;
}
.page_body .left_side .navigation .main_nav .menu-item .sub-menu {
  display: none;
  padding-left: 20px;
}
.page_body .left_side .navigation .main_nav .menu-item .sub-menu.show {
  display: block;
}
.page_body
  .left_side
  .navigation
  .main_nav
  .menu-item
  .sub-menu
  .menu-item:hover
  > a,
.page_body
  .left_side
  .navigation
  .main_nav
  .menu-item
  .sub-menu
  .menu-item
  > a.active {
    background-image: linear-gradient(#324e64, #0c1e2c), linear-gradient(315deg, #3eb7f9, #0abab5);
}
.page_body .right_side {
  transition: margin-left 0.3s ease-in-out;
  margin-left: 260px;
}
.page_body .right_side.active {
  margin-left: 0px;
}
.page_body .right_side .page_topbar .inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  background-color: #152a39;
  border-bottom: 1px solid #273b4b;
  padding: 0px 30px;
  padding-right: 15px;
}
.page_body .right_side .inner .hamburger {
  position: relative;
}
.page_body .right_side .inner .hamburger .hamburger_btn {
  width: 35px;
  cursor: pointer;
}
.page_body .right_side .inner .hamburger .hamburger_btn span {
  width: 100%;
  height: 3px;
  background-color: var(--white-color);
  display: block;
  margin-bottom: 6px;
}
.page_body .right_side .inner .hamburger .hamburger_btn span:last-child {
  margin-bottom: 0;
}
.page_body .right_side .inner .mobile_logo {
  max-width: 120px;
  display: none;
}
.page_body .right_side .inner .dropdown .dropdown-toggle {
  display: flex;
  border: none;
  align-items: center;
  padding: 0 15px;
  border-radius: 0;
  font-size: 14px;
  font-weight: 400;
  color: var(--white-color);
  height: 80px;
  transition: 0.3s all;
  box-shadow: none;
}
.page_body .right_side .inner .dropdown .dropdown-toggle img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 15px;
}
.page_body .right_side .inner .dropdown.show .dropdown-toggle,
.page_body .right_side .inner .dropdown .dropdown-toggle:hover {
  /* background-color: var(--white-color);*/
  /*color: var(--color-one);*/
  outline: none;
  background-image: linear-gradient(#0c1e2c, #0c1e2c),
    linear-gradient(315deg, #3eb7f9, #0abab5);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  color: #fff;
}
.page_body .right_side .inner .dropdown .dropdown-menu {
  border-radius: 0;
  min-width: -webkit-fill-available;
  background-color: #152a39;
  border: none;
  padding: 0;
}
.page_body
  .right_side
  .inner
  .dropdown
  .dropdown-menu
  .dropdown-item:not(:last-child) {
  border-bottom: 1px solid #273b4b;
}
.page_body .right_side .inner .dropdown .dropdown-menu .dropdown-item {
  color: #fff;
  padding: 10px 15px;
}

.page_body
  .right_side
  .inner
  .dropdown
  .dropdown-menu
  .dropdown-item:is(:hover, .active) {
  background-color: #0c1e2c;
  color: #fff;
}
.page_body .right_side .content_wrapper {
  background-color: #0c1e2c;
  height: calc(100vh - 80px);
  padding: 30px;
  padding-bottom: 0px;
  overflow-y: auto;
  background-image: url(../images/background.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.page_header .right_actions,
.page_header {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.page_header .right_actions .form-control {
  height: 34px;
  margin-bottom: 30px;
}
.page_header .btn {
  height: 38px;
}

.custom_tabs .nav {
  border-bottom: 0;
  margin-bottom: 15px;
  width: 100%;
  justify-content: center;
}
/* .custom_tabs .nav .nav-item {
  flex: 1 0 0%;
} */
.custom_tabs .nav .nav-item .nav-link {
  width: 100%;
  background-color: var(--color-two);
  border-radius: 0;
  color: var(--white-color);
  margin-bottom: 15px;
  border: none;
  white-space: nowrap;
  padding: 10px 45px;
  font-size: 16px;
  position: relative;
  cursor: pointer;
}
.custom_tabs .nav .nav-item .nav-link:after {
  content: '';
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 15px solid transparent;
  display: flex;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}
.custom_tabs .nav .nav-item:hover .nav-link,
.custom_tabs .nav .nav-item .nav-link.active {
  background-color: var(--color-link);
}
.custom_tabs .nav .nav-item .nav-link.active::after {
  border-top-color: var(--color-link);
}

/* -----------------------------------------------New Code---------------------------------- */

.w-100-120 {
  width: calc(100% - 120px);
}
.w-100px {
  width: 100px;
}
.me-20px {
  margin-right: 20px;
}
.user_bio li {
  list-style-type: none;
  float: left;
  margin: 0 25px 5px 0;
}
.user_bio li strong {
  margin-right: 10px;
}
.font-12 {
  font-size: 12px;
}

.box {
  border: 1px solid var(--color-two);
  border-radius: 8px;
}
.filter_box .form-control {
  border-radius: 25px !important;
}
.filter_box .filter_btn {
  margin-right: -15px;
  margin-left: 15px;
}
.card {
  position: relative;
  margin-bottom: 30px;
  background-color: #0c1e2c;
  box-shadow: inset 0 14px 4px -12px rgba(0, 0, 0, 0.5),
    inset 0 9px 55px -22px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}
.card .card-header {
  background-color: transparent;
  color: #fff;
  padding: 0px 15px;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-color: #273b4b;
  flex-wrap: wrap;
}

.card .card-footer {
  position: relative;
  border-color: #273b4b;
  background-color: #0c1e2c;
  box-shadow: inset 0 14px 4px -12px rgba(0, 0, 0, 0.5),
    inset 0 9px 55px -22px rgba(0, 0, 0, 0.5);
  border-radius: 0 0 10px 10px;
}
.card .card-header > .btn {
  margin: 10px 0;
}
.card .card-header .card-title {
  line-height: normal;
  font-size: 20px;
  margin-bottom: 0;
  margin: 10px 0;
}
.card .card-header .card-title small {
  font-weight: 500;
}
.card .card-body {
  padding: 15px;
}
.card .card-header .action_btns {
  margin-bottom: 10px;
}
.card .card-header .action_btns > .btn {
  margin-top: 10px;
}
.card .card-header .action_btns .btn:not(:last-child) {
  margin-right: 10px;
}
.card .card-body .action_btns .btn {
  margin-left: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.card .card-body .action_btns .btn:first-child {
  margin-left: 0px;
}
.card.has_col .card-body {
  padding-bottom: 0;
}
.widget_box.card {
  margin-bottom: 30px;
  /* background-color: var(--color-two); */
  color: var(--white-color);
  text-align: center;
  padding: 15px;
  font-size: 32px;
  font-weight: 600;
  cursor: pointer;
  border: 0.1rem solid transparent;
  outline: none;
  background-image: linear-gradient(#0c1e2c, #0c1e2c),
    linear-gradient(315deg, #3eb7f9, #0abab5);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  position: relative;
  z-index: 1;
  border-radius: 10px;
}
.widget_box.card .inner {
  all: unset;
  background-color: #0c1e2c;
  box-shadow: inset 0 0.7rem 0.6rem rgba(0, 0, 0, 0.27);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  border-radius: 10px;
}
.widget_box.card .inner::before {
  display: none;
}
.widget_box.card:after {
  content: '';
  border-radius: 2rem;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -2;
  width: 100%;
  height: 100%;
  background-color: #00dbde;
  background-image: linear-gradient(315deg, #3eb7f9, #0abab5);
  filter: blur(0.8rem);
  transition: 0.5s ease;
}
.widget_box.no_link {
  cursor: auto;
}
.widget_box .title {
  color: var(--white-color);
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 500;
}
.input-group .input-group-text {
  background: transparent;
  border: none;
  padding-left: 0;
  color: var(--black-color);
}
.input-group .form-control {
  padding-right: 58px;
}
.input-group .form-control:focus {
  background-color: #fff;
}
.input-group .icon {
  position: absolute;
  top: 0;
  right: -1px;
  height: 43px;
  width: 43px;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  background-color: var(--color-link);
  color: var(--white-color);
  margin: 0;
  font-size: 18px;
  z-index: 100;
}
.input-group.left .form-control {
  padding-left: 58px;
  padding-right: 15px;
}
.input-group.left .icon {
  left: -1px;
}
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
  margin-bottom: 0;
  margin-right: 8px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch .slider:before {
  position: absolute;
  content: '';
  height: 21px;
  width: 21px;
  left: 3px;
  bottom: 2.1px;
  background-color: white;
  transition: 0.4s;
  top: 2px;
}

.switch input:checked + .slider {
  background-color: #152a39;
}

.switch input:focus + .slider {
  box-shadow: 6px 8px 6px rgba(0, 0, 0, 0.58);
}

.switch input:checked + .slider:before {
  transform: translateX(24px);
}

/* Rounded sliders */
.switch .slider.round {
  border-radius: 30px;
  box-shadow: 6px 8px 6px rgba(0, 0, 0, 0.58);
}
.switch .slider.round:before {
  border-radius: 50%;
}
.custom-modal .modal-content {
  box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%),
    0 1px 3px 0 rgb(0 0 0 / 12%);
  border-radius: 10px;
  border: none;
}
.custom-modal .modal-title {
  margin-bottom: 0;
  font-size: 22px;
  font-weight: 500;
  text-shadow: none;
}
.custom-modal .btn-close {
  position: absolute;
  top: -15px;
  right: -15px;
  margin: 0;
  width: 20px;
  height: 20px;
  border: 2px solid var(--white-color);
  background-color: #0c1e2c;
  border-radius: 50%;
  color: var(--white-color);
  line-height: 0;
  z-index: 1;
  transition: 0.3s all;
  opacity: 1;
  box-shadow: none;
  background-image: none;
  font-size: 18px;
}
.custom-modal .btn-close:hover {
  background-color: #0c1e2c;
  color: var(--white-color);
  border-color: #273b4b;
}
.custom-modal .modal-body {
  padding: 15px;
  padding-top: 30px;
}
.form-group {
  background-color: #0c1e2c;
  border-radius: 25px;
  position: relative;
}
.form-group:before {
  content: '';
  box-shadow: 1rem 1rem 1.4rem #000;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 25px;
  z-index: 0;
}
.form-group > .verified {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  right: 20px;
  font-size: 20px;
}
.form-group > .isCopy {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  right: -50px;
  font-size: 20px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.form-group > textarea::placeholder,
.form-group > input::placeholder {
  color: grey;
}
.form-group > input[readonly],
.form-group > input:disabled,
.form-group > textarea,
.form-group > select,
.form-group > input {
  position: relative;
  border: 0.1rem solid #0c1e2c;
  outline: 0;
  background-color: #0c1e2c;
  box-shadow: -6px -6px 6px #1f3d55;
  z-index: 1;
  padding: 11px 20px;
  text-transform: none;
  border-radius: 25px;
  color: #fff;
}
input:-internal-autofill-selected {
  background-color: #0c1e2c !important;
}
.form-group > textarea:focus,
.form-group > select:focus,
.form-group > input:focus {
  border: 0.1rem solid transparent;
  outline: none;
  background-image: linear-gradient(#0c1e2c, #0c1e2c),
    linear-gradient(315deg, #0abab5, #3eb7f9);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  box-shadow: inset 0 0.7rem 0.6rem rgba(0, 0, 0, 0.27);
  background-color: transparent;
  color: #fff;
}
.form-group > select:focus > option {
  background-color: #0c1e2c;
}
.form-group:focus-within {
  box-shadow: -6px -6px 7px rgba(62, 183, 249, 0.6);
}
.form-group > .form-control:focus,
.form-group > .form-floating:focus-within,
.form-group > .form-select:focus {
  z-index: 5;
}
.form-group.has-value:before {
  box-shadow: inset -0.5rem -0.6rem 1rem hsla(0, 0%, 81%, 0.16);
  z-index: 1;
  cursor: text;
  pointer-events: none;
}
.form-group.has-value input,
.form-group.has-value textarea {
  box-shadow: inset 0.5rem 0.7rem 0.6rem rgba(0, 0, 0, 0.38);
  z-index: 0;
}

.button.outer-login {
  width: auto;
  height: 43px;
  border-radius: 25px;
  background-color: #0c1e2c;
  box-shadow: -6px -6px 6px #1f3d55;
  cursor: pointer;
  padding: 0.375rem 32px;
  border: 0.2rem solid transparent;
  outline: none;
  background-image: linear-gradient(#0c1e2c, #0c1e2c),
    linear-gradient(315deg, #3eb7f9, #0abab5);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  color: #fff;
}

.button.outer-login {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  position: relative;
}
.page-item > .page-link {
  background-color: #152a39;
  border: none;
  box-shadow: 6px 8px 6px rgba(0, 0, 0, 0.58);
  color: var(--white-color);
}
.page-item.active > .page-link {
  background: transparent linear-gradient(85deg, #0abab5, #3eb7f9) 0 0 no-repeat
    padding-box;
}
.Toastify {
  .Toastify__toast-container {
    .Toastify__toast {
      background: #153f65;
      min-height: 50px;

      .Toastify__toast-body {
        font-size: 14px;
        margin: 0;
        color: var(--white-color);
      }

      .Toastify__close-button {
        color: var(--white-color);
        align-self: auto;
        padding-left: 15px;
        border-left: 0.1px solid var(--white-color);
      }
    }
  }
}

@keyframes ldsRing {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.spinny-loader {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  background-color: rgb(var(--black-color-rgb), 0.4);
  display: flex;
  align-items: center;
  justify-content: center;

  .lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 64px;
      height: 64px;
      margin: 8px;
      border: 4px solid #45c5ff;
      border-radius: 50%;
      animation: ldsRing 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: #45c5ff transparent transparent transparent;
      @include media-breakpoint-down(md) {
        width: 32px;
        height: 32px;
      }
      &:nth-child(1) {
        animation-delay: -0.45s;
      }
      &:nth-child(2) {
        animation-delay: -0.3s;
      }
      &:nth-child(3) {
        animation-delay: -0.15s;
      }
    }
  }
}
